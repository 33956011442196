import React from 'react';
import { formatMoney, formatTitle } from '../../../lib/formats';
import { Category } from '../../../models';
import { PLACEHOLDER } from '../../../config';
import { useActiveCurrency } from '../../../hooks';

interface ItemDetailsProps {
  url: string;
  title: string;
  category: Category[];
  units?: number;
  total?: number;
}

export default function SummaryDetails({
  url,
  title,
  category,
  units,
  total,
}: ItemDetailsProps) {
  const [activeCurrency] = useActiveCurrency();

  return (
    <div className="h-24 w-full flex">
      <div className="h-full w-2/5 flex justify-center">
        <img
          placeholder={PLACEHOLDER}
          className="h-full"
          src={url ?? PLACEHOLDER}
          alt=""
        />
      </div>

      <div className="h-full w-3/5 px-3 relative text-left">
        <p className="text-2sm mb-3 font-semibold">{title}</p>

        {category.map((cat) => (
          <p
            className="text-xs xl:text-sm font-semibold text-secondary-400 mr-2"
            key={cat?.slug}
          >
            {formatTitle(cat?.name)}
          </p>
        ))}
        <div className="text-2sm sm:hidden text-secondary-500">
          <p>
            Unidades <span className="font-bold">{units}</span>
          </p>
          <p>
            Total{' '}
            <span className="font-bold">{`${activeCurrency?.symbol} ${formatMoney(
              total,
              activeCurrency?.symbol
            )}`}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
