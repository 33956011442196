import React from 'react';
import { useUser } from '../../hooks';
import { Order } from '../../models';
import OrderCard from './OrderCard';

const Orders = () => {
  const [user] = useUser();
  const [orders, setOrders] = React.useState<Order[]>([]);

  React.useEffect(() => {
    if (user) {
      if (user?.client?.orders) {
        setOrders(user?.client?.orders);
      }
    }
  }, [user]);

  return (
    <div className="w-full px-5 py-16 md:px-16 md:py-24 lg:px-28 xl:px-40 lg:py-24">
      <div className="w-full">
        <h1 className="uppercase text-secondary-500 tracking-wide font-bold text-3xl mb-12">
          Mis Órdenes
        </h1>
      </div>
      <div className="h-full w-full flex flex-col justify-center items-center">
        {orders.length === 0 && user ? (
          <div className="w-full flex flex-col justify-center items-center">
            <img className="h-72" alt="" src="/img/empty-order2.png" />
            <p className="text-lg text-primary-500 font-bold uppercase">
              Su lista de órdenes está vacía
            </p>
          </div>
        ) : null}
        {orders?.map((order) => <OrderCard key={order?._id} order={order} />)}
      </div>
    </div>
  );
};

export default Orders;
