import React from 'react';

interface TestimonyCardProps {
  title: string;
  message: string;
  author: string;
  authorImg: string;
}

const TestimonyCard = ({
  title,
  message,
  author,
  authorImg,
}: TestimonyCardProps) => (
  <div className="max-w-md py-4 px-8 bg-white shadow rounded my-10">
    <div className="flex justify-center md:justify-end -mt-16">
      <img
        className="w-20 h-20 object-cover rounded-full border-2 border-green-500"
        src={authorImg}
        alt="Author"
      />
    </div>
    <div>
      <h2 className="text-gray-800 text-3xl leading-normal font-semibold capitalize">
        {title}
      </h2>
      <p className="mt-2 text-gray-600">{message}</p>
    </div>
    <div className="flex justify-end mt-4">
      <p className="text-xl leading-normal font-medium text-green-500 capitalize">
        {author}
      </p>
    </div>
  </div>
);

export default TestimonyCard;
