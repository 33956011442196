import React from 'react';

const ScrollIndicator = () => (
  <div className="flex items-center justify-center pb-8 opacity-50">
    <div className="mouse-icon">
      <span className="mouse-wheel" />
    </div>
  </div>
);

export default ScrollIndicator;
