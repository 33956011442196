import React from 'react';
import Link from 'next/link';
import NProgress from 'nprogress';
import { useRouter, Router } from 'next/router';
import { useMutation } from '@apollo/client';
import { SIGN_OUT } from '../graphql/mutations';
import { useUser } from '../hooks';

const UserDropdown = () => {
  const [userDropdownOpen, setUserDropdownOpen] = React.useState(false);
  const [user, setUser] = useUser();
  const [signOut] = useMutation(SIGN_OUT);
  const router = useRouter();

  // toggle user dropdown logic
  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  Router.events.on('routeChangeStart', () => setUserDropdownOpen(false));

  const logout = async () => {
    try {
      NProgress.start();
      const { data } = await signOut();
      if (data.signOut.success) {
        setUser(null);
        router.push('/enter');
      }
    } catch (err) {
      console.log(err);
    } finally {
      NProgress.done();
    }
  };

  return (
    <div className="relative">
      <button
        type="button"
        onClick={toggleUserDropdown}
        className="w-full flex items-center lg:justify-between cursor-pointer py-1 lg:px-2 hover:text-primary hover:border-4 outline-none focus:outline-none"
      >
        <img
          src="https://placekitten.com/30/30"
          className="rounded-full mr-2 w-10"
          alt=""
        />{' '}
        <span
          className="inline-block truncate mr-2 capitalize"
          style={{ maxWidth: '120px' }}
        >
          {user.name}
        </span>
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </button>
      {userDropdownOpen && (
        <div
          className={`w-full text-sm leading-normal items-center z-40 border p-1 bg-white divide-y ${
            userDropdownOpen ? 'absolute' : 'hidden'
          } w-40 right-0`}
          // onMouseEnter={() => setUserDropdownOpen(true)}
          // onMouseLeave={() => setUserDropdownOpen(false)}
        >
          <Link href="/profile">
            <a className="px-4 py-2 block text-gray-800 hover:text-primary">
              Editar Perfil
            </a>
          </Link>
          <Link href="/shopping-cart">
            <a className="px-4 py-2 block text-gray-800 hover:text-primary">
              Carrito de Compra
            </a>
          </Link>
          <button
            onClick={logout}
            type="button"
            className="block w-full text-left"
          >
            <span className="px-4 py-2 block hover:text-primary text-red-600">
              Cerrar Sesión
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
