export { default as AddIcon } from './AddIcon';
export { default as AddressIcon } from './AddressIcon';
export { default as AddToCartIcon } from './AddToCartIcon';
export { default as ArrowIcon } from './ArrowIcon';
export { default as CartIcon } from './CartIcon';
export { default as CloseIcon } from './CloseIcon';
export { default as DoneIcon } from './DoneIcon';
export { default as FacebookIcon } from './FacebookIcon';
export { default as FaceIcon } from './FaceIcon';
export { default as GoBackIcon } from './GoBackIcon';
export { default as InstagramIcon } from './InstagramIcon';
export { default as LoginIcon } from './LoginIcon';
export { default as LogoutIcon } from './LogoutIcon';
export { default as MenuIcon } from './MenuIcon';
export { default as RemoveIcon } from './RemoveIcon';
export { default as SearchIcon } from './SearchIcon';
export { default as UserIcon } from './UserIcon';
