import React from 'react';
import Head from 'next/head';
import Drift from 'react-driftjs';
import { useRouter } from 'next/router';
import { useQuery, useMutation } from '@apollo/client';
import {
  User,
  Setting,
  Location,
  Currency,
  Plugin,
  Category,
} from '../../models';
import {
  CURRENT_USER,
  CURRENT_CONFIG,
  GET_LOCATIONS,
  GET_CURRENCY,
  GET_PLUGINS,
  GET_CATEGORIES,
} from '../../graphql/queries';
import { SIGN_OUT } from '../../graphql/mutations';
import {
  ToastContextProvider,
  AppContext,
  ShopCartContextProvider,
} from '../../context';
import LocationModal from '../modals/LocationModal';
import WarningModal from '../modals/WarningModal';
import { Banner, Footer, Loader, Navbar } from '..';

interface LayoutProps {
  children: React.ReactChild | React.ReactChild[];
}

interface MainLayoutProps {
  locations: Location[];
  settings: Setting;
  categories: Category[];
  isOpen: boolean;
  warningIsOpen: boolean;
  setWarningOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (e) => void;
  children: React.ReactChild | React.ReactChild[];
  updateIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainLayout = ({
  locations,
  settings,
  categories,
  isOpen,
  warningIsOpen,
  children,
  setWarningOpen,
  onChange,
  updateIsOpen,
}: MainLayoutProps) => (
  <>
    <Banner text={settings?.banner?.text} display={settings?.banner?.active} />
    <ShopCartContextProvider>
      <Navbar categories={categories} updateIsOpen={updateIsOpen} />
    </ShopCartContextProvider>
    <div className="remaining-height w-full">{children}</div>
    <LocationModal
      isOpen={isOpen}
      locations={locations}
      onChange={onChange}
      updateIsOpen={updateIsOpen}
    />
    <WarningModal
      isOpen={warningIsOpen}
      close={() => {
        setWarningOpen(false);
      }}
    />

    <Footer />
  </>
);

function Layout({ children }: LayoutProps) {
  const router = useRouter();
  const [user, setUser] = React.useState<User>(null);
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [activeCurrency, setActiveCurrency] = React.useState<Currency>(null);
  const [settings, setSettings] = React.useState<Setting>(null);
  const [location, setLocation] = React.useState<string>('');
  const [plugins, setPlugins] = React.useState<Plugin[]>([]);
  const [isOpen, setOpen] = React.useState(false);
  const [warningIsOpen, setWarningOpen] = React.useState(true);
  const [signOut] = useMutation(SIGN_OUT);
  const updateIsOpen = React.useCallback(setOpen, [setOpen]);

  // Apollo connections to db
  const { loading: userLoading, data: userData } = useQuery<{
    me: User;
  }>(CURRENT_USER, {
    fetchPolicy: 'network-only',
  });

  const { data: currencyData, loading: currencyLoading } = useQuery<{
    currencies?: Currency[];
  }>(GET_CURRENCY, {
    fetchPolicy: 'network-only',
  });

  const { loading: settingsLoading, data: settingsData } = useQuery<{
    currentSetting: Setting;
  }>(CURRENT_CONFIG, {
    fetchPolicy: 'network-only',
  });

  const { loading: pluginsLoading, data: pluginsData } = useQuery<{
    plugins?: Plugin[];
  }>(GET_PLUGINS, {
    fetchPolicy: 'network-only',
  });

  const { loading: locationsLoading, data: locationsData } = useQuery<{
    locations?: Location[];
  }>(GET_LOCATIONS, {
    fetchPolicy: 'network-only',
  });

  const { data: categoriesData, loading: categoriesLoading } = useQuery<{
    categories?: Category[];
  }>(GET_CATEGORIES, {
    fetchPolicy: 'network-only',
  });

  // Currency hook
  React.useEffect(
    function syncCurrencies() {
      if (!currencyLoading && currencyData?.currencies) {
        setCurrencies(currencyData?.currencies);
        setActiveCurrency(currencyData?.currencies[0]);
      }
    },
    [currencyData, currencyLoading]
  );

  // Location hook
  React.useEffect(
    function syncLocations() {
      if (locationsData?.locations) {
        const { locations } = locationsData;
        if (locations?.length === 1) {
          setLocation(locationsData?.locations[0]?._id);
        } else if (localStorage.getItem('otc-location')) {
          const locationId = localStorage.getItem('otc-location');
          const _location = locations.find((l) => l?._id === locationId);
          if (_location) {
            setLocation(locationId);
          } else {
            setOpen(true);
          }
        } else {
          setOpen(true);
        }
      }
    },
    [locationsData, locationsLoading]
  );

  // Set location in LocalStorage
  React.useEffect(
    function updateLocationInLocalStorage() {
      if (location) {
        if (localStorage.getItem('otc-location') !== location) {
          localStorage.setItem('otc-location', location);
        }
      }
    },
    [location]
  );

  // Settings hook
  React.useEffect(
    function syncSettings() {
      if (!settingsLoading && settingsData?.currentSetting) {
        setSettings(settingsData?.currentSetting);
      }
    },
    [settingsData, settingsLoading]
  );

  // Plugins hook
  React.useEffect(
    function syncPlugins() {
      if (!pluginsLoading && pluginsData?.plugins) {
        setPlugins(pluginsData?.plugins);
      }
    },
    [pluginsData, pluginsLoading]
  );

  // User hook
  React.useEffect(
    function syncUserData() {
      if (!userLoading) {
        if (userData?.me?.privilege === 0) {
          setUser(userData?.me);
          setWarningOpen(false);
        } else {
          signOut();
          setUser(null);
        }
      }
    },
    [userData, userLoading, signOut]
  );

  // Categories hook
  React.useEffect(
    function syncUserData() {
      if (!categoriesLoading && categoriesData) {
        setCategories(categoriesData?.categories ?? []);
      }
    },
    [categoriesData, categoriesLoading]
  );

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setLocation(e.target.value);
    setOpen(false);
  };

  const getPluginData = (pluginName: string, data: string) => {
    const plugin = plugins?.find((p) => p?.name === pluginName);

    if (!plugin?.active) return ''; // If not active, return empty string
    return (
      plugin?.credentials?.find((c) => c?.name?.toLowerCase() === data)
        ?.value ?? ''
    );
  };

  return (
    <AppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        user,
        setUser,
        settings,
        setSettings,
        locationId: location,
        currencies,
        setCurrencies,
        activeCurrency,
        setActiveCurrency,
        plugins,
        setPlugins,
      }}
    >
      {userLoading ||
      settingsLoading ||
      locationsLoading ||
      pluginsLoading ||
      categoriesLoading ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <Head>
            <html lang="es" />
            <link rel="preconnect" href="https://res.cloudinary.com" />

            {/* Main SEO */}
            <meta name="title" content="BodeBar" />
            <meta name="description" content="BodeBar" />

            {/* Open Graph SEO */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://bodebar/" />
            <meta property="og:image" content="/img/logo.png" />
            <meta property="og:title" content="BodeBar" />
            <meta property="og:description" content="BodeBar" />
            <meta property="og:site_name" content="BodeBar" key="ogsitename" />

            {/* Twitter SEO */}
            <meta property="twitter:url" content="https://bodebar/" />
            <meta property="twitter:title" content="BodeBar" />
            <meta property="twitter:description" content="BodeBar" />
            <meta property="twitter:image" content="/img/logo.png" />
            <script
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${getPluginData('Facebook Pixel', 'token')});
              `,
              }}
            />
            <noscript>
              <img
                alt=""
                height="1"
                width="1"
                style={{ display: 'none' }}
                src={`https://www.facebook.com/tr?id=${getPluginData(
                  'Facebook Pixel',
                  'token'
                )}&ev=PageView&noscript=1`}
              />
            </noscript>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${getPluginData(
                'Google Analytics',
                'token'
              )}`}
            />
            <script
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${getPluginData('Google Analytics', 'token')}', {
                page_path: window.location.pathname,
              });
            `,
              }}
            />
          </Head>
          <ToastContextProvider>
            <Drift appId={getPluginData('Drift', 'token')} />

            <div className="h-screen box-border">
              {!(router.pathname === '/enter') ? (
                <MainLayout
                  locations={locationsData?.locations ?? []}
                  settings={settings}
                  categories={categories}
                  isOpen={isOpen}
                  warningIsOpen={warningIsOpen}
                  setWarningOpen={setWarningOpen}
                  onChange={onChange}
                  updateIsOpen={updateIsOpen}
                >
                  {children}
                </MainLayout>
              ) : (
                children
              )}
            </div>
          </ToastContextProvider>
        </>
      )}
    </AppContext.Provider>
  );
}

export default Layout;
