/* eslint-disable @typescript-eslint/no-explicit-any */
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL, trackingID: string): void => {
  (window as any).gtag('config', trackingID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, params }): void => {
  (window as any).gtag('event', action, params);
};
